import { createRouter, createWebHistory } from 'vue-router'
import Images from "@/views/Images.vue"
import About from "@/views/About.vue"
import Contact from "@/views/Contact.vue"
import Info from "@/views/Info.vue"
import Admin from "@/views/Admin.vue"
import Auth from "@/views/Auth.vue"


import { Amplify } from 'aws-amplify'

const routes = [
  {
    path: '/',
    name: 'Images',
    component: Images
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("match")

    Amplify.Auth.currentAuthenticatedUser().then((data) => {
      console.log("User data : ", data)
      next()
    }).catch((e) => {
      console.log("error : ", e)

      next({
        path: '/auth'
      });
    });
  }
  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Amplify.Auth.currentAuthenticatedUser()
      .then((data) => {
        console.log("User data : ", data)
        next()
      })
      .catch((e) => {
        console.log("error : ", e)
        next({
          path: '/auth',
          query: { redirect: to.fullPath }
        })
      })
  } else {
    next() // make sure to always call next()!
  }
})

export default router
