// import { Amplify, Auth, Storage } from 'aws-amplify';


const config = {
    Auth: {
        identityPoolId: "eu-west-3:049756f2-e57c-4f51-b7d5-e1828bab061d", //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'eu-west-3', // REQUIRED - Amazon Cognito Region
        userPoolId: 'eu-west-3_XQqLKOFob', //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '4u9p6kfm8ae1i7chmbp3e9fvj9', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
        AWSS3: {
            bucket: 'business-card-romain-images-dev',
            region: 'eu-west-3',
        }
    }
}



export default config