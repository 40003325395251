<template>
  <div class="info-container">
    <div v-if="!displayLegal" class="credits-container">
      <p><strong>Crédits</strong></p>
      <p><strong>.</strong></p>
      <br />

      <p class="title">Photographie</p>
      <p>Marianne Cardon, Véronique Huyghe, Mario Simon Lafleur, Raphaël Zarka</p>

      <p class="title">Site web</p>
      <p>Developpé par Etienne Mallo</p>

      <p class="title">Typographie</p>
      <p>Noto Sans SC</p>

      <br />
      <p>.</p>
      <p>© Romain Poivey 2022</p>
      <p class="link" @click="displayLegal = true">Mentions légales</p>
    </div>
    <div v-if="displayLegal" class="legal-container">
      <div>
        <p><strong>Mention légales</strong></p>
        <p><strong>.</strong></p>
        <br />
        <p>Éditeur et directeur de la publication</p>
        <br />
        <p>© 2022 Romain Poivey</p>
        <p>contact@romainpoivey.com</p>
        <p>+33 (0) 6.19.72.51.20</p>
        <br />
        <p>....................................</p>
        <br />
        <p>Titulaire d’un CAPE</p>
        <br />
        <p>Crealead - SCOP SA à capital variable.</p>
        <p>Hôtel de la coopération - 55 rue Saint Cléophas, - 34 070 MONTPELLIER N°</p>
        <p>SIRET : 438 076 200 00023 - N° TVA Intracommunautaire : FR56438076200</p>
        <br />
        <p>....................................</p>
        <br />
        <p>Hébergeur</p>
        <br />
        <p>© 2020, Amazon Web Services, Inc. ou ses sociétés apparentées,</p>
        <p>440 Terry Ave North, Seattle, WA 98109, États-Unis</p>
        <br />
      </div>
      <br />
      <div>
        <p><strong>Propriété intelectuelle</strong></p>
        <p><strong>.</strong></p>
        <br />
        <p>
          Les éléments présents sur le site tels que les marques, dessins, modèles, images, textes, photos, logos,
          chartes graphiques, sans que cette liste ne soit exhaustive, sont protégés notamment par des droits de
          propriété intellectuelle qui leur sont ou seront reconnus selon les lois en vigueur.
        </p>
        <br />
        <p>
          Toute reproduction ou représentation, totale ou partielle d’un de ces éléments, sans autorisation expresse est
          interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la
          propriété intellectuelle.
        </p>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "Info",
  data() {
    return {
      displayLegal: false,
    };
  },
};
</script>

<style scoped>
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: left;
  padding: 0 80px 0 210px;
}

.credits-container {
  max-width: 350px;
}

.title {
  margin: 20px 0 2px 0;
  font-weight: 500;
}

.link {
  text-decoration: underline;
  font-size: 0.7rem;
  font-weight: bold;
}
.link:hover {
  cursor: pointer;
  color: var(--main-color);
}

.legal-container {
  max-width: 1000px;
}

p {
  margin: 0;
}
</style>
