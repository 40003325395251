<template>
  <div class="signin">
    <h2>Bienvenue</h2>
    <div class="formcontainer">
      <input placeholder="identifiant" v-model="form.username" class="input" />
      <input placeholder="mot de passe" type="password" v-model="form.password" class="input" />
      <button v-on:click="signIn" @keydown.enter="signIn" class="signin-button">Sign In</button>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
// import { AmplifyEventBus } from "aws-amplify-vue";

export default {
  name: "Auth",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async signIn() {
      const { username, password } = this.form;
      try {
        const user = await Auth.signIn(username, password);
        console.log("attente d'identification...");
        this.$store.commit("MUTATE_STATE", { state: "user", mutation: user });
        // AmplifyEventBus.$emit("authState", "signedIn");
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.signin {
  display: flex;
  flex: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  max-width: 300px;
  margin: 100px auto 100px auto;
  padding: 10px;

  /*border: 1px solid black;*/
  border-radius: 5px;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.2);

  background-color: rgb(250, 250, 250);
}

.formcontainer {
  display: flex;
  flex: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formcontainer > * {
  margin: 10px 0px 10px 0px;
}

.input {
  width: 200px;
  height: 30px;
  padding: 5px;

  border: 0.5px solid #1f2a36;
  border-radius: 2px;
}

.signin-button {
  width: 211px;
  height: 41px;
  padding: 5px;

  color: #fff;

  border: 0px solid #1f2a36;
  background-color: #1f2a36;
  border-radius: 2px;
}
.signin-button:hover {
  cursor: pointer;
}
</style>
