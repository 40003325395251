<template>
  <div class="about-container">
    <div>
      <div>
        Designer industriel,<br />
        diplômé de l’ESAD de Reims (2013) puis de l’ENSCI - Les Ateliers (2017),
        <br />
        vit & travail entre Paris et le sud de la France.
        <br /><br />
        Passionné par le vocabulaire formel que les matériaux et leurs procédés de transformation induisent, il cultive
        une proximité avec la matière et la technique en se rapprochant dès que possible du « savoir-faire », qu’il
        vienne du monde de l’artisanat ou de l’industrie.
        <br /><br />
        Basé sur une approche analytique et créative, sa pratique, constamment guidée par un désir de sobriété, s’appuie
        sur des transferts de technologies et de savoirs, répondant à des enjeux protéiformes.
        <br /><br />
        Attaché aux valeurs sociales et culturelles du design, il collabore à des échelles et pour des partenaires
        variés. De groupes industriels, à des artistes contemporains, de la maison de luxe à la PME familiale. On le
        sollicite dans la gestion de projets stratégiques, du conseil à la conception, au développement de produits
        jusqu’à la direction artistique et l’identité de marque.
      </div>
      <div @click.stop="displayDetails = !displayDetails" class="text button">
        <svg
          class="svg"
          :class="{ 'more-details-active': displayDetails }"
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="12px"
          height="12px"
          fill-rule="evenodd"
        >
          <path
            fill-rule="evenodd"
            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
          />
        </svg>
      </div>
      <div class="about-details" :class="{ 'active-details': displayDetails }">
        <p>
          Portfolio disponible sur <a :href="`mailto:contact@romainpoivey.com?subject=${emailSubject}`">demande</a>.
        </p>

        <div class="logos">
          <div><img src="@/assets/logos/EDF.png" /></div>
          <div><img src="@/assets/logos/IFTH.png" /></div>
          <div><img src="@/assets/logos/ASCA.png" /></div>
          <div><img src="@/assets/logos/palaisdetokyo.png" /></div>
          <div><img src="@/assets/logos/Hermes.png" /></div>
          <div><img src="@/assets/logos/E_ARTSUP.png" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      language: "fr",
      emailSubject: "Prise de contact / Portfolio",
      displayDetails: false,
    };
  },
};
</script>

<style scoped lang="scss">
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: left;
  padding: 0 80px 0 210px;
}
.about-container > *:first-child {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.language-picker {
  display: flex;
  margin-left: auto;
  margin-bottom: 30px;
}

.language-picker > * {
  margin-left: 10px;
}
.language-picker > *:hover {
  cursor: pointer;
}

.active {
  font-weight: 700;
}

.logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 40px;
  gap: 10px;
}
img {
  max-width: 75px;
  max-height: 75px;
  // margin: 5px 20px 5px 20px;
}

.button {
  font-weight: bold;
}
.button:hover {
  cursor: pointer;
  color: var(--main-color);
}
.svg {
  transition: transform 0.3s ease;
}
.svg:hover {
  fill: var(--main-color);
}
.more-details-active {
  transform: rotate(45deg);
  transform-origin: center center;
}

.about-details {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  &.active-details {
    max-height: 80vh;
    overflow: scroll;
  }
}
</style>
