<template>
  <div class="images-container">
    <ImageComponent
      v-for="(project, index) in loadedProjects"
      :key="'image_' + index"
      :image="project.img"
      :imageNumber="projects.length"
      :imageZindex="imagesZindex[index]"
      :project="project"
      :updatePosition="updatePosition"
    />

    <div
      :style="zIndexStyle"
      :class="{ focus: displayProjectDescription, 'pointer-target': displayProjectDescription }"
      class="background"
    ></div>
    <!-- <div v-if="$store.state.viewState === 'business-card'" class="industrial-designer">Designer industriel</div> -->
    <WordSwipe
      v-if="$store.state.viewState === 'business-card'"
      class="industrial-designer"
      staticString="Design"
      :array="['Produit', 'Espace', 'Stratégie', 'Industriel']"
    />
  </div>
</template>

<script>
import ImageComponent from "@/components/Image.vue";
import WordSwipe from "@/components/WordSwipe.vue";
import _ from "lodash";

export default {
  name: "Images",
  components: {
    ImageComponent,
    WordSwipe,
  },
  data() {
    return {
      imagesName: [],
      images: [],
      imagesZindex: [],
      index: 0,
      interval: undefined,
      projects: [],
      updatePosition: true,
    };
  },
  computed: {
    displayProjectDescription() {
      return this.$store.state.displayProjectDescription;
    },
    zIndexStyle() {
      return this.displayProjectDescription
        ? {
            zIndex: this.projects.length - 1,
          }
        : {};
    },
    loadedProjects() {
      return this.projects.filter((project) => "img" in project);
    },
  },
  watch: {
    "$store.state.viewState": {
      deep: true,
      handler(newVal) {
        if (newVal === "projects") {
          setTimeout(() => {
            this.imagesZindex.unshift(this.imagesZindex.pop());
            this.startPoping();
          }, 3500);
        }
      },
    },
    imagesName() {
      this.preloadImages(this.imagesName);
      this.imagesZindex = this.imagesName.map((val, index) => index);
      this.index = 0;
      // this.startPoping();
    },
    displayProjectDescription(newVal) {
      newVal ? this.stopPoping() : this.startPoping();
    },
    projects() {
      this.projects.forEach((project) => {
        const img = new Image();
        img.src = process.env.VUE_APP_S3_IMAGES + project.image;
        img.onload = () => {
          project.img = img;
        };
      });
      this.imagesZindex = this.projects.map((val, index) => index);
      this.index = 0;
      // this.startPoping();
    },
  },
  methods: {
    startPoping() {
      this.interval = setInterval(() => {
        this.imagesZindex.unshift(this.imagesZindex.pop());
        if (!this.updatePosition) {
          this.updatePosition = true;
        }
      }, 3000);
    },
    stopPoping() {
      clearInterval(this.interval);
      this.interval = null;
    },
    async getProjects() {
      await this.$api()
        .get("data")
        .then((response) => {
          this.projects = _.shuffle(response.data);
          this.$store.commit("MUTATE_STATE", { state: "data", mutation: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    preloadImage(url, onload) {
      const img = new Image();
      img.src = process.env.VUE_APP_S3_IMAGES + url;
      img.onload = () => {
        onload(img);
      };
    },
    preloadProjects() {
      this.projects.forEach((project) => {
        const img = new Image();
        img.src = process.env.VUE_APP_S3_IMAGES + project.image;
        img.onload = () => {
          project.img = img;
        };
      });
    },
    getWidth() {
      return "50%";
    },
  },
  created() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.stopPoping();
      } else {
        if (!this.interval && this.$store.state.viewState === "projects") {
          this.startPoping();
        }
      }
    });
  },
  mounted() {
    this.getProjects();
    window.addEventListener("keydown", (event) => {
      if (!this.displayProjectDescription) {
        this.updatePosition = false;
        if (event.key === "ArrowLeft") {
          this.stopPoping();
          this.imagesZindex.push(this.imagesZindex.shift());
          this.startPoping();
        }
        if (event.key === "ArrowRight") {
          this.stopPoping();
          this.imagesZindex.unshift(this.imagesZindex.pop());
          this.startPoping();
        }
      }
    });
  },
};
</script>

<style scoped>
.images-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
}

.background {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  z-index: 0;
  transition: opacity 0.5s ease-in-out 0.4s;
}

.focus {
  opacity: 0.85;
}

.cursor-cross:hover {
  cursor: url("../assets/cross.png"), auto;
}

.industrial-designer {
  align-self: end;
  bottom: 65px;
  margin-left: auto;
  position: inherit;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 200px;
}
</style>
