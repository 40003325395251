<template>
  <div class="contact-container">
    <a target="_blank" href="https://www.linkedin.com/in/romain-poivey-565379139/"
      ><img src="../assets/linkedin_logo.png"
    /></a>
    <a href="mailto:contact@romainpoivey.com?subject=Prise de contact"><img src="../assets/arobase.png" /></a>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      language: "fr",
    };
  },
};
</script>

<style scoped>
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: left;
}

img {
  max-width: 65px;
  max-height: 65px;
  filter: opacity(25%);
  transition: filter 0.15s;
}

img:hover {
  /* filter: opacity(100%); */
  filter: opacity(100%) invert(28%) sepia(69%) saturate(5024%) hue-rotate(211deg) brightness(101%) contrast(106%);
  cursor: pointer;
}
</style>
