<template>
  <div class="word-swipe-container">
    <span class="static">{{ staticString }}</span>
    <!-- <div class="array" v-for="(string, index) in array" :key="index">{{ string }}</div> -->
    <div>
      <div class="flipper">
        <Transition>
          <div v-if="animate">{{ array[index] }}</div>
        </Transition>
        <Transition>
          <div v-if="!animate">{{ array[index] }}</div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WordSwipe",
  props: {
    staticString: String,
    array: Array,
  },
  data() {
    return {
      index: 0,
      animate: true,
    };
  },
  computed: {
    dynamicString() {
      return this.array[1];
    },
  },
  mounted() {
    setInterval(() => {
      this.index = this.index === this.array.length - 1 ? 0 : this.index + 1;
      this.animate = !this.animate;
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
.word-swipe-container {
  display: flex;
  height: 23px;

  div {
    overflow: hidden;
    position: relative;
    float: right;
  }
}

.static {
  margin-right: 5px;
  font-weight: bold;
}

.flipper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--main-color);
  transition: margin-top 0.2s;
  margin-top: 0;
}

.v-enter-active {
  transition: margin-top 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  margin-top: -23px;
}
</style>
