<template>
  <div class="project-description-container">
    <div class="title">{{ description?.title }}</div>
    <div v-if="$store.state.orientation !== 'portrait'">-</div>
    <div class="text partner">{{ description?.partner }}</div>
    <div class="text">{{ description?.context }}</div>
    <div class="text">{{ description?.year }}</div>
    <div v-if="description?.details" @click.stop="displayDetails = !displayDetails" class="text button">
      <svg
        class="svg"
        :class="{ 'more-details-active': displayDetails }"
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="12px"
        height="12px"
        fill-rule="evenodd"
      >
        <path
          fill-rule="evenodd"
          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
        />
      </svg>
    </div>
    <div v-if="displayDetails" class="text details">{{ description?.details }}</div>
  </div>
</template>

<script>
export default {
  name: "ProjectDescription",
  props: {
    description: {
      type: Object,
    },
  },
  data() {
    return {
      displayDetails: false,
    };
  },
};
</script>

<style scoped>
.project-description-container {
  height: 100%;
  width: 100%;
  padding: 80px;

  text-align: left;

  white-space: pre-line;
}

.project-description-container > * {
  margin-bottom: 10px;
}

.title {
  font-size: 2rem;
}

.text {
  font-weight: 100;
}

.partner,
.details {
  font-size: 0.9rem;
}

.button {
  font-weight: bold;
}
.button:hover {
  cursor: pointer;
  color: var(--main-color);
}
.svg {
  transition: transform 0.3s ease;
}
.svg:hover {
  fill: var(--main-color);
}
.more-details-active {
  transform: rotate(45deg);
  transform-origin: center center;
}

@media (orientation: portrait) {
  .project-description-container {
    padding: 20px;
    /* overflow: scroll; */
    z-index: 999;
  }

  .project-description-container > * {
    margin-bottom: 5px;
  }

  .title {
    font-size: 1.5rem;
  }
}
</style>
