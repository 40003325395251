<template>
  <div class="menu-container">
    <div
      class="title-romain"
      :class="{
        'business-card': $store.state.viewState === 'business-card' && $route.name === 'Images',
        'title-hover': $store.state.viewState === 'business-card' || $route.name !== 'Images',
      }"
      @click="onTitleClick()"
    >
      <p>RO-</p>
      <p>MAIN</p>
      <p>POI-</p>
      <p>VEY</p>
    </div>
    <Transition>
      <div v-if="$store.state.viewState === 'projects' || $route.name !== 'Images'" class="menu-item-container">
        <div class="menu-item" @click="$router.push({ path: '/about' })">A propos</div>
        <div class="menu-item" @click="$router.push({ path: '/contact' })">Contact</div>
        <div class="menu-item" @click="$router.push({ path: '/info' })">
          <span v-if="$route.path !== '/info'">+</span><span v-if="$route.path === '/info'">-</span>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
// import fitty from "fitty";
export default {
  name: "Menu",
  data() {
    return {
      fittyTitle: null,
    };
  },
  computed: {
    pStyle() {
      // console.log(document.getElementsByClassName("title-romain"));
      let el = Array.from(document.getElementsByClassName("title-romain"));

      if (el.length > 0) {
        console.log(el[0]);
        return {
          lineHeight: document.getElementsByClassName("title-romain")[0].style.getPropertyValue("line-height"),
        };
      }
      return {};
    },
  },
  methods: {
    onTitleClick() {
      // this.fittyTitle.unsubscribe();
      this.$router.push({ path: "/" });
      this.$store.commit("MUTATE_STATE", { state: "viewState", mutation: "projects" });
    },
  },
  // mounted() {
  //   this.fittyTitle = fitty(".title-romain");
  //   this.fittyTitle = this.fittyTitle[0];
  // },
};
</script>

<style scoped>
.menu-container {
  height: 100vh;
  width: 100%;
  padding: 20px 80px 20px 80px;

  display: grid;
  grid-template-rows: 4fr auto;
  gap: 40px;
  justify-items: start;

  text-align: left;
}

.title-romain {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  width: 100%;
  transition: font-size 1.5s ease, color 0.3s ease, padding 1.5s ease;
  white-space: pre-line;
  padding-top: 40px;
}

.business-card {
  padding-top: 0px;
  font-size: 23vh;
  z-index: 10;
}

.title-hover:hover {
  color: var(--main-color);
  cursor: pointer;
}

.menu-item-container {
  height: 20vh;
}

.menu-item:hover {
  cursor: pointer;
  font-weight: 500;
  color: var(--main-color);
}

p,
pre {
  margin: 0;
  font-family: inherit;
}

.v-enter-active {
  transition: opacity 0.2s ease 1.5s;
}
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media (orientation: portrait) {
  .menu-container {
    height: 100%;
    padding: 10px;

    display: flex;
    gap: 0;
    justify-content: space-between;
  }

  .title-romain {
    font-size: 2.3rem;
    padding-top: 0;
    width: fit-content;
  }

  .menu-item-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: min-content;
    width: fit-content;
    white-space: nowrap;
  }

  .menu-item {
    margin-left: 10px;
  }
}
</style>
