<template>
  <div id="app-container" @click="onClickApp">
    <Menu class="menu" />
    <Transition>
      <ProjectDescription
        v-if="displayProjectDescription"
        :description="projectDescription"
        ref="projectDescription"
        class="project-description"
      />
    </Transition>

    <!-- <router-view class="router"/> -->
    <router-view v-slot="{ Component }">
      <keep-alive include="Images">
        <component :is="Component" />
      </keep-alive>
    </router-view>

    <Cursor v-if="$store.state.orientation === 'landscape'" />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import ProjectDescription from "@/components/ProjectDescription.vue";
import Cursor from "@/components/Cursor.vue";

export default {
  name: "App",
  components: {
    Menu,
    ProjectDescription,
    Cursor,
  },
  computed: {
    projectDescription() {
      return this.$store.state.projectDescription;
    },
    displayProjectDescription() {
      return this.$store.state.displayProjectDescription;
    },
    projectDescriptionToggleStyle() {
      return this.displayProjectDescription
        ? {
            opacity: 1,
          }
        : {
            opacity: 0,
          };
    },
  },
  watch: {
    "document.visibilityState": {
      deep: true,
      handler(newVal) {
        console.log(newVal);
      },
    },
  },
  methods: {
    onClickApp() {
      this.displayProjectDescription
        ? this.$store.commit("MUTATE_STATE", { state: "displayProjectDescription", mutation: false })
        : null;
    },
    handleOrientationChange() {
      if (window.screen.height > window.screen.width) {
        setTimeout(() => {
          this.$store.commit("MUTATE_STATE", { state: "viewState", mutation: "projects" });
        }, 500);

        this.$store.commit("MUTATE_STATE", { state: "orientation", mutation: "portrait" });
      } else if (window.screen.height < window.screen.width) {
        this.$store.commit("MUTATE_STATE", { state: "orientation", mutation: "landscape" });
      }
    },
    mobileHeightSupport() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  mounted() {
    this.$api()
      .get("data")
      .then((response) => {
        this.$store.commit("MUTATE_STATE", { state: "data", mutation: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
    this.handleOrientationChange();
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.mobileHeightSupport();
    window.addEventListener("resize", () => {
      this.mobileHeightSupport();
    });
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
}
@media (orientation: portrait) {
  html {
    font-size: 12px;
  }
}
#app {
  font-family: "Noto Sans SC", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  box-sizing: border-box !important;
}

#app-container {
  display: grid;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

@media (orientation: landscape) {
  #app-container {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "menu router";
  }
}

@media (orientation: portrait) {
  #app-container {
    grid-template-rows: 140px auto;
    grid-template-areas:
      "menu"
      "router";
  }
}

.menu {
  grid-area: menu;
  max-width: 400px;
  max-height: 100vh;
}

.router {
  grid-area: router;
}

.project-description {
  grid-area: menu;
  background-color: white;
}

.button {
  color: grey;
}
.button:hover {
  cursor: pointer;
  color: black;
}

.v-enter-active {
  transition: opacity 0.2s ease 0.45s;
}
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

:root {
  --main-color: #0066ff;
}
</style>
