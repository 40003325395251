<template>
  <div class="admin-container">
    <h1>Administration</h1>
    <div>
      <h3>Données</h3>
      <div class="table">
        <div class="header">
          <div>Titre</div>
          <div>Contexte</div>
          <div>Collaborateur</div>
          <div>Année</div>
          <div>Détails</div>
          <div>Image</div>
          <div>Actions</div>
        </div>
        <TableRow v-for="(project, index) in data" :key="index" :row="project" class="row" />
        <div class="button" @click="addProject()">ajouter une référence</div>
      </div>
      <!-- <TableForm
        v-if="data.length > 0"
        :rows="data"
        :columnNames="columnNames"
        @save="onSave($event)"
        @delete="onDelete($event)"
      /> -->
    </div>
    <button @click="signOut">déconnexion</button>
  </div>
</template>

<script>
import TableRow from "@/components/Admin/TableRow.vue";

import { v4 as uuidv4 } from "uuid";
// import TableForm from "../components/Admin/TableForm.vue";
import { Auth } from "aws-amplify";

export default {
  name: "Admin",
  components: {
    TableRow,
    // TableForm,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    columnNames() {
      return Object.keys(this.data[0]);
    },
  },
  methods: {
    getData() {
      this.$api()
        .get("data")
        .then((response) => {
          console.log(response.data);
          this.data = response.data;
          this.data = this.data.sort((a, b) => (a.title > b.title ? 1 : -1));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addProject() {
      let emptyEntry = JSON.parse(JSON.stringify(this.data[0]));
      Object.keys(emptyEntry).forEach((key) => (emptyEntry[key] = ""));
      emptyEntry.id = uuidv4();
      this.data.push(emptyEntry);
    },
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.admin-container {
  text-align: left;
  margin: 80px;
}

.table {
  display: flex;
  flex-direction: column;
}

.header {
  display: grid;
  grid-template-columns: repeat(7, 150px);
  gap: 5px;
  margin-bottom: 20px;
  font-weight: 500;
}

.row {
  display: grid;
  grid-template-columns: repeat(7, 150px);
  gap: 5px;
  margin-bottom: 10px;
}

.el-form
  .el-form--default
  .el-form--label-top
  .el-form-item
  .el-form-item__label
  .el-form-item--small
  .el-form-item__content {
  display: grid !important;
  width: 100% !important;
}

.el-input__inner {
  font-family: inherit;
}

.el-popper {
  font-family: "Work Sans", sans-serif !important;
}

.el-select-dropdown {
  font-family: inherit;
}

.el-select-dropdown__item {
  font-size: 12px !important;
}

input {
  border-radius: 0 !important;
  margin: 0px !important;
  width: 100% !important;
}

textarea {
  border-radius: 0 !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
  margin: 0px !important;
}
.el-select__input {
  font-family: inherit;
  margin-left: 10px;
}

.el-select .el-select--small {
  margin: 0px !important;
}

.el-select-dropdown__item.selected {
  color: var(--main-color) !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: var(--background-side-menu) !important;
}

.el-progress-bar__outer,
.el-progress-bar__inner {
  border-radius: 0px !important;
}

.el-progress-bar__innerText {
  font-size: 0rem !important;
}
</style>
