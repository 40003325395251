import { createStore } from 'vuex'
import _ from "lodash"

export default createStore({
  state: {
    viewState: "business-card",
    orientation: "",
    switcher: 0,
    positionHistory: _.shuffle(Array.from(new Array(6), (val, index) => index + 1)),
    displayProjectDescription: false,
    projectDescription: {
      id: "4179846b-969f-48d7-af13-eb73c26d0ee4",
      title: "Titre",
      partner: "Collaborateur",
      context: "Contexte",
      year: "Année",
      image: "image.png"
    },
  },
  mutations: {
    MUTATE_STATE(state, data) {
      state[data.state] = data.mutation
    }

  },
  actions: {
    fetch_data() {
      this.$api()
        .get("data")
        .then((response) => {
          console.log(response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  modules: {
  },
  getters: {
    getGlobalPosition: (state) => {
      // Weighted Random Position Picker
      let position;
      let expPositionHistory = state.positionHistory.map((p) => Math.pow(p, 1.5))
      let sumOfWeight = expPositionHistory.reduce((partialSum, a) => partialSum + a, 0);
      let rnd = Math.floor(Math.random() * sumOfWeight)
      for (let index = 0; index < expPositionHistory.length; index++) {
        let pos = expPositionHistory[index]
        if (rnd < pos) {
          state.positionHistory = state.positionHistory.map((pos, i) => {
            if (i == index) {
              return 1
            }
            return pos + 1
          })
          position = index + 1;
          break;
        }
        rnd -= pos;
      }
      return position
    }
  }
})
