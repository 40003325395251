<template>
  <div>
    <textarea type="text" class="entry" :disabled="!editing" :class="{ editing: editing }" v-model="rowData.title" />
    <textarea type="text" class="entry" :disabled="!editing" :class="{ editing: editing }" v-model="rowData.context" />
    <textarea type="text" class="entry" :disabled="!editing" :class="{ editing: editing }" v-model="rowData.partner" />
    <textarea type="text" class="entry" :disabled="!editing" :class="{ editing: editing }" v-model="rowData.year" />
    <textarea type="text" class="entry" :disabled="!editing" :class="{ editing: editing }" v-model="rowData.details" />
    <div>
      <img :src="imageSrc" class="image" />
      <input :disabled="!editing" type="file" name="file" :id="row.id" class="inputfile" @change="onFile($event)" />
      <label :for="row.id" class="button">Choisir une autre image</label>
    </div>

    <div class="actions">
      <div v-if="!editing" @click="onEdit()" class="button">éditer</div>
      <div v-if="editing" @click="onSave()" class="button">sauvegarder</div>
      <div @click="onDelete()" class="button">supprimer</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableRow",
  props: {
    row: Object,
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    rowData() {
      return this.row;
    },
    imageSrc() {
      return process.env.VUE_APP_S3_IMAGES + this.rowData.image;
    },
  },
  methods: {
    onEdit() {
      this.editing = true;
    },
    onSave() {
      this.editing = false;
      this.$api()
        .put("data", this.rowData)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDelete() {},
    async onFile(e) {
      console.log(e);
      const file = e.target.files[0];
      this.rowData.image = file.name;
      console.log(file.name);
      try {
        await this.$Storage.put(file.name, file);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    },
    adjustHeight(el) {
      el.style.height = el.scrollHeight > el.clientHeight ? el.scrollHeight + "px" : "60px";
    },
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  gap: 7px;
}

.entry:hover {
  cursor: not-allowed;
}

.editing:hover {
  cursor: text;
}

textarea {
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
}

.image {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
</style>
