import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import api from "./plugins/api"





const app = createApp(App)

// ########## AMPLIFY ##########
import { Amplify, Storage, Auth } from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue'
import config from './plugins/aws-amplify'

Amplify.configure(config)
// app.use(AmplifyPlugin, Amplify)
// app.prototype.$Auth = Auth
// app.prototype.$Storage = Storage
app.config.globalProperties.$Auth = Auth
app.config.globalProperties.$Storage = Storage

// ########## AMPLIFY ##########


// ##### ELEMENT DESIGN #####
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
app.use(ElementPlus);
// ##########################

app.config.globalProperties.$api = api;


app.use(store).use(router).mount('#app')

