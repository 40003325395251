<template>
  <div class="pointer" :class="{ hide: isHover }" ref="pointer">
    <!-- <span></span> -->
    <div class="btn" :class="{ plus: !displayProjectDescription, cross: displayProjectDescription }">
      <span class="bar bar-1"></span>
      <span class="bar bar-2"></span>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";

export default {
  name: "Cursor",
  data() {
    return {
      pointerTarget: null,
      isHover: true,
    };
  },
  computed: {
    displayProjectDescription() {
      return this.$store.state.displayProjectDescription;
    },
  },
  mounted() {
    let self = this;
    document.addEventListener("mousemove", function (e) {
      self.$refs.pointer.style.left = e.clientX + "px";
      self.$refs.pointer.style.top = e.clientY + "px";
    });
    document.onmousemove = throttle(function (e) {
      var elm = document.elementFromPoint(e.clientX, e.clientY);
      if (elm.classList.contains("pointer-target") === true) {
        self.pointerTarget = elm.innerHTML;
        self.isHover = false;
      } else {
        self.pointerTarget = "";
        self.isHover = true;
      }
    }, 100);
  },
};
</script>

<style lang="scss">
.pointer {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  &.hide {
    opacity: 0;
    transform: scale(0);
  }

  span {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    border: 1px solid gray;
    border-radius: 20px;
    display: block;
    transition: transform, 0.3s ease-out;
  }
}

.pointer-target {
  cursor: none;
}

.btn {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  width: 30px;
  height: 30px;
  border: var(--main-color) 2px solid;
  border-radius: 30px;
  transform: scale(0.75);

  .bar {
    display: inline-block;
    position: absolute;
    top: 14px;
    left: 0;
    width: 3px;
    height: 15px;
    border-radius: 3px;
    border-color: var(--main-color);
    background-color: var(--main-color);
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
  }

  &.pause {
    .bar-1 {
      transform: translateX(17.5px) translateY(2px) rotate(0deg);
    }
    .bar-2 {
      transform: translateX(25px) translateY(2px) rotate(0deg);
    }
  }

  &.play {
    .bar-1 {
      transform: translateX(22px) translateY(-2px) rotate(-55deg);
    }
    .bar-2 {
      transform: translateX(22px) translateY(6px) rotate(-125deg);
    }
  }

  &.cross {
    .bar-1 {
      transform: translateX(22px) translateY(2px) rotate(-45deg);
    }
    .bar-2 {
      transform: translateX(22px) translateY(2px) rotate(45deg);
    }
  }

  &.plus {
    .bar-1 {
      transform: translateX(22px) translateY(2px) rotate(90deg);
    }
    .bar-2 {
      transform: translateX(22px) translateY(2px) rotate(0deg);
    }
  }
}
</style>
