<template>
  <img
    :src="image.src"
    @click.stop="onClickImage()"
    ref="image"
    :style="focus ? focusedStyle : styleObject"
    class="image pointer-target"
    v-show="false"
  />
</template>

<script>
export default {
  name: "Image",
  props: {
    image: Image,
    imageZindex: Number,
    imageNumber: Number,
    project: Object,
    updatePosition: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isMounted: false,
      top: 0,
      left: 0,
      focus: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  computed: {
    styleObject() {
      return {
        top: this.top,
        left: this.left,
        zIndex: this.imageZindex,
      };
    },
    focusedStyle() {
      const top = `${(this.$parent.$el.clientHeight - this.$refs.image.clientHeight) / 2}px`;
      const left = `${(this.$parent.$el.clientWidth - this.$refs.image.clientWidth) / 2}px`;
      return {
        top: top,
        left: left,
        zIndex: this.imageNumber,
        transform: "scale(1.12)",
      };
    },
    displayProjectDescription() {
      return this.$store.state.displayProjectDescription;
    },
  },
  watch: {
    imageZindex() {
      if (this.imageZindex == this.imageNumber - 1) {
        if (this.updatePosition) {
          let position = this.getPosition();
          this.top = position.top;
          this.left = position.left;
        }
        this.$refs.image.style.transition = "none";
        this.$refs.image.style.display = "block";
      }
    },
    displayProjectDescription(newVal) {
      this.$refs.image.style.transition =
        "top 0.5s ease-in-out, left 0.5s ease-in-out, z-index 0.5s ease-in-out, transform 0.5s ease-in-out";
      if (!newVal) {
        this.focus = false;
      }
    },
  },
  methods: {
    onClickImage() {
      if (this.displayProjectDescription) {
        this.$store.commit("MUTATE_STATE", { state: "displayProjectDescription", mutation: false });
        this.focus = false;
      } else {
        this.$store.commit("MUTATE_STATE", { state: "displayProjectDescription", mutation: true });
        this.$store.commit("MUTATE_STATE", { state: "projectDescription", mutation: this.project });
        this.focus = true;
      }
    },
    randomInt(min, max, power) {
      return Math.floor(Math.pow(Math.random(), power) * (max - min) + min);
    },
    getPosition() {
      if (this.isMounted) {
        var display = this.$refs.image.style.display;
        this.$refs.image.style.display = "";
        let maxTop = this.$parent.$el.clientHeight - this.$refs.image.clientHeight;
        let maxLeft = this.$parent.$el.clientWidth - this.$refs.image.clientWidth;
        this.$refs.image.style.display = display;
        let position = this.$store.getters.getGlobalPosition;
        switch (position) {
          case 1:
            // top left
            return {
              top: "0px",
              left: "0px",
            };
          case 2:
            // bottom left
            return {
              top: `${maxTop}px`,
              left: "0px",
            };
          case 3:
            // bottom right
            return {
              top: `${maxTop}px`,
              left: `${maxLeft}px`,
            };
          case 4:
            // top right
            return {
              top: "0px",
              left: `${maxLeft}px`,
            };
          case 5:
            // top middle
            return {
              top: "0px",
              left: `${maxLeft / 2}px`,
            };
          case 6:
            // bottom middle
            return {
              top: `${maxTop}px`,
              left: `${maxLeft / 2}px`,
            };
        }
      }
    },
    dragMouseDown(event) {
      event.preventDefault();
      if (!this.focus) {
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        document.onmousemove = this.elementDrag;
        document.onmouseup = this.closeDragElement;
      }
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.top = this.$el.offsetTop - this.positions.movementY + "px";
      this.left = this.$el.offsetLeft - this.positions.movementX + "px";
    },
    closeDragElement(event) {
      event.preventDefault();
      document.onmouseup = null;
      document.onmousemove = null;
      return false;
    },
  },
  mounted() {
    this.isMounted = true;
    let position = this.getPosition();
    this.top = position.top;
    this.left = position.left;
    if (this.$refs.image.style.zIndex == "") this.$refs.image.style.zIndex = "1";
  },
};
</script>

<style scoped lang="scss">
.image {
  position: absolute;
  display: block;
  max-width: 65%;
  max-height: 85%;
  width: auto;
  height: auto;
  z-index: 1;
  /* transition: all 0.5s ease-in-out; */
}
</style>
